import React, { useState } from 'react';
import { Form, FormInstance, Input } from "antd";
import { emailPattern, numberPattern } from "../tools/tools";
import ImageCaptcha from "./captcha";

const FormItem = () => {
  const [ form ] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);
  const [ open, setOpen ] = useState(false);
  const [ formData, setFormData ] = useState<any>(null);

  const handleSubmit = () => {
    formRef.current?.submit();
  }

  const handleKeyDown = (event: any) => {
    // 允许数字键、小数点和退格键
    if (!(event.key === 'Backspace' || event.key === '.' || event.key === ',' || event.key === '-' ||
      (event.key >= '0' && event.key <= '9'))) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className={ 'flex items-center justify-center w-full ' }>
        <div className={ 'flex flex-col items-center justify-center max-w-7xl px-4' }>
          <div className={ 'text-2xl lg:text-4xl font-black' }>お問い合わせ</div>
          <div
            className={ 'text-center text-base lg:text-xl my-8 text-[#666666] font-black whitespace-pre-wrap' }>{ 'アプリに関するお問い合わせはこちらからお気軽にご相談ください。 \n追って担当者よりお返事させていただきます。' }
          </div>
          <div className={ 'right w-full max-w-7xl flex flex-col' }>
            <Form
              form={ form }
              layout={ 'horizontal' }
              ref={ formRef }
              autoComplete='off'
              className={ 'w-full' }
              onFinish={ (value) => {
                setFormData(value);
                setOpen(true);
              } }
            >
              <Form.Item
                label={ <div className={ 'w-28 lg:w-36 sm:mt-2 text-start font-bold lg:text-xl' }>氏名</div> }
                colon={ false }
                name='username'
                required
                rules={ [
                  {
                    required: true,
                    message: '氏名を入力してください',
                  },
                ] }
              >
                <Input placeholder='氏名'/>
              </Form.Item>
              <Form.Item
                label={ <div className={ 'w-28 lg:w-36 sm:mt-2 text-start font-bold lg:text-xl' }>電話番号</div> }
                colon={ false }
                name='phone'
                required
                rules={ [
                  {
                    required: true,
                    message: '電話番号を入力してください',
                  },
                  {
                    pattern: numberPattern,
                    message: '電話番号を正しく入力してください',
                  },
                ] }
              >
                <Input placeholder='電話番号' maxLength={ 11 } onKeyDown={ handleKeyDown }/>
              </Form.Item>

              <Form.Item
                label={ <div className={ 'w-28 lg:w-36 sm:mt-2 text-start font-bold lg:text-xl' }>メールアドレス</div> }
                colon={ false }
                name='email'
                required
                rules={ [
                  {
                    required: true,
                    message: 'メールアドレスを入力してください',
                  },
                  {
                    pattern: emailPattern,
                    message: 'メールアドレスを正しく入力してください',
                  },
                ] }
              >
                <Input placeholder='メールアドレス'/>
              </Form.Item>
              <Form.Item
                label={ <div className={ 'w-28 lg:w-36 sm:mt-2 text-start font-bold lg:text-xl' }>ご用件</div> }
                colon={ false }
                name='subject'
                required
                rules={ [
                  {
                    required: true,
                    message: 'ご用件を入力してください',
                  },
                ] }
              >
                <Input placeholder='ご用件'/>
              </Form.Item>
              <Form.Item
                label={ <div className={ 'w-28 lg:w-36 text-start font-bold lg:text-xl' }>詳細内容</div> }
                colon={ false }
                name='content'
                required
                rules={ [
                  {
                    required: true,
                    message: '詳細内容を入力してください',
                  },
                ] }
              >
                <Input.TextArea rows={ 8 } placeholder='詳細内容'/>
              </Form.Item>
            </Form>

            <div className={ 'flex justify-center items-center' }>
              <div
                className={ 'flex items-center justify-center bg-black px-24 mt-6 mb-14 text-base lg:text-2xl text-white font-medium rounded-full h-[50px] cursor-pointer' }
                onClick={ handleSubmit }>送信
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageCaptcha open={ open } setOpen={ setOpen } formData={ formData } handleBack={ () => {
        setFormData(null);
        formRef.current?.resetFields();
      } }></ImageCaptcha>

    </div>
  );
};
export default FormItem;
