import React from 'react'
import './index.css'
import Router from './router/index'
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider>
      <Router></Router>
    </ConfigProvider>
  );
}

export default App;
