import axios from "axios";
import { message } from 'antd';
import { baseUrl } from "../tools/tools";

const service = axios.create({
    baseURL: `${baseUrl}`,
    timeout: 20000,
    headers:{
      'accept-language': 'ja',
      'Content-Type': 'application/json',
    }
  }
);

// 请求拦截、设置请求头
service.interceptors.request.use(config => {
  return config;
}, error => {
  console.log("error：", error)
  return Promise.reject(error)
})

// 响应拦截
service.interceptors.response.use(response => {
  return Promise.resolve(response);
}, error => {
  return error.response;
})

export default service
