import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ja: {
    translation: require('./ja.json')
  },
  zh: {
    translation: require('./zh.json')
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getLocal(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

export function getLocal() {
  return localStorage.getItem("language") ?? 'ja'
}

export function setLocal(code) {
  localStorage.setItem("language", code)
}