import React from 'react';

const CompanyInfo = () => {
  return (
    <div className={ 'flex items-center justify-center w-full pb-10 mt-4' }>
      <div className={ 'flex flex-col items-center justify-center max-w-7xl' }>
        <div className="container ">
          <div className={'text-xl sm:text-2xl font-bold mb-3 text-white'}>会社概要</div>
          <div className={ 'md:text-xl font-medium text-white' }>販売業者名：有限会社ティーアンドコー</div>
          <div className={ 'py-2 md:text-xl font-medium text-white' }>責任者名：代表取締役 青木拓磨</div>
          <div className={ 'pb-2 md:text-xl font-medium text-white' }>所在地：埼玉県志木市上宗岡4−26−20</div>
          <div className={ 'text-base md:text-xl font-medium text-white' }>電話番号：050-3728-2573</div>
        </div>
      </div>
    </div>
  );
};
export default CompanyInfo;
