import React, {Component} from 'react'
import Index from '../pages/index'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

export default class index extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Index/>} path='/'></Route>
        </Routes>
      </BrowserRouter>
    )
  }
}
