import React from 'react';
import Header from '../components/header';
import Footer from "../components/footer";
import { appStoreUrl, googlePlayUrl } from "../tools/tools";
import CompanyInfo from "../components/companyInfo";
import FormItem from "../components/formItem";
import '../index.css'

function Home() {

  return (
    <div className={ ' w-full min-h-screen mx-auto flex flex-col items-center bg-white' }>

      <div className={ 'flex items-center justify-center w-full bg-white' }>
        <div className={ 'flex flex-col items-center justify-center w-full sm:max-w-7xl px-4' }>
          <Header></Header>
        </div>
      </div>

      <div className={ 'h-[1px] bg-[#CFC8C8] w-full' }></div>

      <div className={ 'flex items-center w-full' }>
        <img src='/image/logo_top.png' className={ 'w-full h-[190px] sm:h-full items-start object-cover' } alt=''></img>
        <div className={'-ml-[55%]'}>
          <div className={ 'bg-[#A51B1B] text-white font-bold px-3 py-2 lg:py-6 text-sm md:text-xl lg:text-[40px] mb-1.5 lg:mb-3 max-w-[530px]' }>バイクを持っている人も持っ</div>
          <div className={ 'bg-[#A51B1B] text-white font-bold px-3 py-2 lg:py-6 text-sm md:text-xl lg:text-[40px] mb-3 lg:mb-10 max-w-[410px]' }>ていない人もみんなで</div>
          <div className={ 'custom-font font-bold text-[18px] md:text-3xl lg:text-[52px] lg:leading-10' }>ツーリングを楽しもう！</div>
        </div>
      </div>

      <div className={ 'max-w-7xl mx-auto flex flex-col' }>
        <div className={ 'flex items-center justify-center flex-wrap gap-4 pt-10 md:pt-20' }>
          <img src='/image/logo_apple.png' alt='' className={ 'w-40 sm:w-80 h-auto cursor-pointer' }
               onClick={ async () => {
                 window.open(appStoreUrl)
               } }></img>
          <img src='/image/logo_google.png' alt='' className={ 'w-40 sm:w-80 h-auto cursor-pointer' }
               onClick={ async () => {
                 window.open(googlePlayUrl)
               } }></img>
        </div>
      </div>

      <div className={ 'max-w-7xl mx-auto flex flex-col' }>
        <div className={ 'flex flex-col gap-1' }>
          <div className={ 'px-8 sm:px-4 pt-10 md:pt-20 flex flex-col sm:flex-row-reverse items-center justify-evenly gap-8' }>
            <div className={'w-full sm:max-w-[500px] sm:max-h-[500px]'}>
              <img src="/image/first.svg" className={'w-full sm:w-[500px] sm:h-[500px]'} alt=""/>
            </div>
            <div className={ 'flex flex-col items-start sm:max-w-[600px]' }>
              <img src='/image/logo_tag.svg' alt='' className={ 'h-5 md:h-10' }></img>
              <span className={'custom-font text-2xl md:text-5xl font-bold mt-2 md:mt-4 mb-3 md:mb-10'}>ツーリングを記録しょう</span>
              <span className={ 'text-base md:text-2xl font-bold leading-7 md:leading-10' }>
                Let's・ラ・Goでは、イベント情報が確認できたり、ツーリング仲間との連絡が取れたりと仲間との交流ができます！
              </span>
            </div>
          </div>

          <div
            className={ 'px-8 sm:px-4 pt-10 md:pt-20 flex flex-col sm:flex-row items-center justify-around gap-8 ' }>
            <div className={'w-full sm:max-w-[500px] sm:max-h-[500px]'}>
              <img src="/image/second.svg" className={'w-full sm:w-[500px] sm:h-[500px]'} alt=""/>
            </div>
            <div className={ 'flex flex-col items-start sm:max-w-[600px]' }>
              <img src='/image/logo_tag.svg' alt='' className={ 'h-5 md:h-10' }></img>
              <span className={'custom-font text-2xl md:text-5xl font-bold mt-2 md:mt-4 mb-3 md:mb-10'}>好きなバイクを体験しょう</span>
              <span className={ 'text-base md:text-2xl font-bold leading-7 md:leading-10' }>
                Let's・ラ・Goでは、レンタルもシェアも利用でき、バイクを持っている人も持っていない人も一緒に楽しめます！さぁ、みんなでバイクを楽しみましょう。
              </span>
            </div>
          </div>

          <div className={ 'px-8 sm:px-4 pt-10 md:pt-20 flex flex-col sm:flex-row-reverse items-center justify-around gap-8 ' }>
            <div className={'w-full sm:max-w-[470px] sm:max-h-[580px]'}>
              <img src="/image/third.png" className={'w-full sm:w-[470px] sm:h-[580px]'} alt=""/>
            </div>
            <div className={ 'flex flex-col items-start sm:max-w-[700px]' }>
              <img src='/image/logo_tag.svg' alt='' className={ 'h-5 md:h-10' }></img>
              <span className={'custom-font text-2xl md:text-5xl font-bold mt-2 md:mt-4 mb-3 md:mb-10'}>自分のバイクをシェアできる</span>
              <span className={ 'text-base md:text-2xl font-bold leading-7 md:leading-10' }>
                オーナー登録とバイクの登録をするだけで、誰でも気軽にバイクのシェアができるようになります。
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={ 'flex flex-col items-center justify-center w-full pt-20 md:pt-40' }>
        <div className={ 'text-2xl md:text-4xl font-black mb-14' }>加盟店ご検討中の方,専用窓口</div>
        <img src='/image/logo_line.png' alt='' className={ 'w-32 h-32 md:w-44 md:h-44' }></img>
        <div className={ 'bg-[#D7D7D7] h-[1px] w-full my-14' }></div>
      </div>

      <FormItem></FormItem>

      <Footer></Footer>
    </div>
  );
}

export default Home;
