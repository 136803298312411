import request from './axios'

export async function sendMail(body: any) {
  const data = body;
  data['timestamp'] = Date.now().toString();
  return request({
    url: '/index/sendMail',
    method: 'POST',
    data: data,
  })
}
