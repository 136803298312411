import { Button, Form, FormInstance, Input, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
// import Captcha from "react-captcha-code/build/es";
import '../index.css'
import { imageCodeUrl } from "../tools/tools";
import { sendMail } from "../service/api";

const ImageCaptcha = (props: {
  open: boolean;
  setOpen: (value: boolean) => any;
  formData: any;
  handleBack: any;
}) => {
  const { open, formData } = props;
  const formRef = React.useRef<FormInstance>(null);
  // const [ captcha, setCaptcha ] = useState<string>('');
  const [ url, setUrl ] = useState<string>('');
  const [ timestamp, setTimestamp ] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const time = Date.now().toString();
    setTimestamp(time);
    setUrl(imageCodeUrl + time);
  }, []);

  const handleRefreshCode = () => {
    const time = Date.now().toString();
    setTimestamp(time);
    setUrl(imageCodeUrl + Date.now().toString());
  };

  // const handleClick = useCallback((captcha: any) => {
  //   setCaptcha(captcha);
  // }, []);

  const handleCancel = () => {
    setUrl(imageCodeUrl + Date.now().toString());
    formRef.current?.resetFields();
    props.setOpen(false);
  };

  const handleSubmit = async (value: any) => {
    setLoading(true)
    let params = formData;
    params.codeSign = timestamp;
    params.vCode = value.vCode
    const response = await sendMail({ ...params });
    setLoading(false)
    if (response && response.data.code === 200) {
      setUrl(imageCodeUrl + Date.now().toString());
      formRef.current?.resetFields();
      props.setOpen(false);
      props.handleBack();
      message.success('送信成功しました');
    } else {
      message.error('送信失敗しました');
      setUrl(imageCodeUrl + Date.now().toString());
    }
  };

  return (
    <Modal
      open={ open }
      maskClosable={ false }
      onCancel={ handleCancel }
      footer={ false }
    >

      <div className={ 'mt-6' }>
        <Form
          className={ 'custom-input' }
          layout={ 'horizontal' }
          ref={ formRef }
          autoComplete='off'
          onFinish={ async (value) => {
            await handleSubmit(value);
          } }
        >
          <Form.Item
            label={ <div className={ 'mt-1' }>認証コード</div> }
            colon={ false }
            name="vCode"
            required
            rules={ [
              {
                required: true,
                message: '認証コードを正しく入力してください',
              },
              // {
              //   validator: async (_, value) => {
              //     if (value !== captcha) return Promise.reject(new Error('認証コードを正しく入力してください'));
              //     return Promise.resolve();
              //   },
              // },
            ] }
          >
            <Input placeholder="認証コード" maxLength={ 4 } className={ 'w-full sm:w-[300px]' }/>
          </Form.Item>
        </Form>
        <div className={ 'sm:ml-[88px]' }>
          {/*<Captcha charNum={ 4 } onChange={ handleClick }/>*/ }
          <img src={ url } alt='' onClick={ async () => {
            await handleRefreshCode()
          } }></img>
        </div>
      </div>

      <div className={ 'flex flex-wrap gap-4 items-center justify-end mt-8' }>
        <Button
          loading={loading}
          className={ 'w-24 font-bold' }
          type="primary"
          onClick={ async () => {
            formRef?.current?.submit();
          } }
        >
          提出
        </Button>
      </div>
    </Modal>
  )
};
export default ImageCaptcha;
