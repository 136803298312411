export const cmsUrl = 'https://cms.rentalbike.shop'

export const baseUrl = 'https://www.rentalbike.shop';

// 利用規約
export const appStoreUrl = 'https://apps.apple.com/app/id6463189552'

export const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.takuma_gp.motorental&pli=1'

export const imageCodeUrl = `/index/imageCode?w=108&h=32&codeSign=`

export const emailPattern =
  /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
export const numberPattern = /^[0-9]*$/;
