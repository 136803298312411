import React from 'react';
// import { cmsUrl } from "../tools/tools";

const Header = () => {

  return (
    <div className={ 'flex items-center justify-between w-full h-[80px] bg-white' }>
      <img src='/image/logo_nav.svg' alt='' className={ 'h-6 w-auto' }></img>
      {/*<div*/}
      {/*  className={ 'flex items-center justify-center border-solid border-[2px] border-[#000000] h-10 rounded-full px-6 font-bold cursor-pointer' }*/}
      {/*  onClick={ async () => {*/}
      {/*    window.open(cmsUrl)*/}
      {/*  } }>店舗の方CMSへ*/}
      {/*</div>*/}
    </div>
  );
};
export default Header;
