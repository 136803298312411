import React from 'react';
import CompanyInfo from "./companyInfo";

const Footer = () => {
  return (
    <div className={'flex flex-col justify-center items-center gap-4 text-[#7c7c7c] text-sm pt-8 pb-6 w-full bg-black' }>
      <CompanyInfo></CompanyInfo>
      <div className={'flex items-center justify-center flex-wrap gap-4 mb-8 px-4'}>
        <a className={'text-white underline font-bold cursor-pointer mr-6 md:text-xl'} href='/terms.html'>利用規約</a>
        <a className={'text-white underline font-bold cursor-pointer mr-6 md:text-xl'} href='/privacy.html'>プライバシーポリシー</a>
        <a className={'text-white underline font-bold cursor-pointer mr-6 md:text-xl'} href='/law.html'>特定商取引法表記</a>
        <a className={'text-white underline font-bold cursor-pointer md:text-xl'} href='/feedback.html'>ユーザーデータ削除依頼</a>
      </div>
      <div className={'text-white'}>©Copyright {new Date().getFullYear()} Let’s•ラ•Go All rights reserved.</div>
    </div>
  );
};
export default Footer;
